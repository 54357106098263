<button nz-button nzType="primary" class="btn-add-asset" type="button" (click)="showModalAdd()" *ngIf="checkAdd">
  <em class="fas fa-plus"></em> {{'add_user' | translate }}
</button>
<div class="data-table">
<nz-table #basicTable [nzData]="roles" [nzPageSizeOptions]="pageSize" [nzPageSize]="defaultPage" nzSize="small" nzShowSizeChanger [nzShowTotal]="rangeTemplate" [nzLoading]="loading">
  <thead>
  <tr>
    <th class="text-nowrap">{{'name' | translate }}</th>
    <th class="text-nowrap">{{'created_date' | translate }}</th>
    <th class="text-nowrap">{{'name_action' | translate }}</th>
    <th class="text-nowrap">{{'action' | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data">
    <td class="text-nowrap">{{ data.roleName }}</td>
    <td class="text-nowrap">{{ data.createdDate | dateFormat}}</td>
    <td class="text-nowrap"> <nz-tag *ngFor="let action of data.actionResponses" [nzColor]="'#108ee9'">{{action.name}}</nz-tag></td>
    <td class="text-nowrap">
      <button nz-button nzType="danger" (click)="showConfirm(data.id)" *ngIf="checkDelete">{{'delete' | translate }}</button>
      <button nz-button nzType="default" class="button-update" (click)="showModalUpdate(data)" *ngIf="checkUpdate">{{'edit' | translate }}</button>
    </td>
  </tr>
  </tbody>
</nz-table>
</div>
<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} {{'of' | translate }} {{ total }} {{'items' | translate }}
</ng-template>
<app-add-role [isVisibleAdd]="isVisibleAdd" (data)="addRole($event)" (submitAdd)="closeModalAdd($event)"></app-add-role>
<div *ngIf="role!=null">
  <app-update-role [isVisibleUpdate]="isVisibleUpdate" (submitUpdate)="closeModalUpdate($event)" [role] ="role"></app-update-role>
</div>

